import React, { useContext } from "react";
import { MenuIcon } from "@heroicons/react/outline";
import { AppContext } from "../App";
import { Table } from "../components/Table";

const headers = ["Name", "Score", "Date"];
const data = [
  ["Matt", 340, "Saturday May 2nd, 2021"],
  ["Erin", 223, "Saturday May 2nd, 2021"],
];

const tableData = {
  headers,
  data,
};

export function PlayerScores2() {
  const { setSidebarOpen } = useContext(AppContext);

  return (
    <>
      <div className="md:hidden pl-1 pt-1 sm:pl-3 sm:pt-3">
        <button
          className="-ml-0.5 -mt-0.5 h-12 w-12 inline-flex items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
          onClick={() => setSidebarOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <MenuIcon className="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
      <main className="flex-1 relative z-0 overflow-y-auto focus:outline-none">
        <div className="py-6">
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">
              2 Player Scores
            </h1>
          </div>
          <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
            <div className="py-4">
              <Table tableData={tableData} />
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
