import React, { createContext, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Sidebar } from "./components/Sidebar";
import { Home } from "./pages/Home";
import { IndividualScores } from "./pages/IndividualWordScores";
import { PlayerScores2 } from "./pages/PlayerScores2";
import { PlayerScores4 } from "./pages/PlayerScores4";

interface AppContextProps {
  sidebarOpen: boolean;
  setSidebarOpen: (isOpen: boolean) => void;
}

export const AppContext = createContext<AppContextProps>({
  sidebarOpen: false,
  setSidebarOpen: () => {},
});

export default function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <AppContext.Provider value={{ sidebarOpen, setSidebarOpen }}>
      <div className="h-screen flex overflow-hidden bg-gray-100">
        <Router>
          <Sidebar />
          <div className="flex flex-col w-0 flex-1 overflow-hidden">
            <Switch>
              <Route path="/player-scores-2">
                <PlayerScores2 />
              </Route>
              <Route path="/player-scores-4">
                <PlayerScores4 />
              </Route>
              <Route path="/individual">
                <IndividualScores />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </div>
        </Router>
      </div>
    </AppContext.Provider>
  );
}
